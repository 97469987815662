import { TrackingEvent } from '$shared/components';

export type TrackingDataLayer =
    | PageViewDataLayer
    | ViewProductDataLayer
    | ViewProduct360DataLayer
    | CountrySelectorDataLayer
    | DownloadProductFileDataLayer
    | ProductLeadDataLayer
    | SocialLinkDataLayer
    | WebinarClickDataLayer
    | WebinarLeadDataLayer
    | CatalogueClickDataLayer
    | CatalogueLeadDataLayer
    | FormLeadDataLayer
    | WhitePaperDownloadDataLayer
    | ImageGalleryClickDataLayer
    | VideoPlayDataLayer
    | CategoryFilterDataLayer
    | InternalSearchDataLayer;

export enum TrackingEnvironmentEnum {
    DEV = 'dev',
    PROD = 'prod',
    QA = 'qa',
}

export type BaseDataLayer = {
    event?: TrackingEvent;
    environment?: TrackingEnvironmentEnum;
    market?: string;
};

export type BasePageLayer = {
    page_url?: string;
    country?: string;
};

export type BaseProductLayer = {
    product_name?: string | null;
    product_id?: string | null;
    product_category?: string | null;
    product_category2?: string | null;
    product_category3?: string | null;
};

export type PageViewDataLayer = BaseDataLayer & {
    og_location?: string;
    page_type?: string;
    page_url?: string;
    page_path?: string;
};

export type ViewProductDataLayer = BaseDataLayer & {
    product_name?: string | null;
    product_id?: string | null;
    product_category?: string | null;
    product_category2?: string | null;
    product_category3?: string | null;
};

export type ViewProduct360DataLayer = BaseDataLayer & {
    product_name?: string | null;
    product_id?: string | null;
    page_url?: string;
};

export type CountrySelectorDataLayer = BaseDataLayer & {
    country?: string;
};

export type DownloadProductFileDataLayer = BaseDataLayer & {
    file_name?: string | null;
    file_link?: string | null;
    product_name?: string | null;
    product_id?: string | null;
    page_url?: string;
};

export type ProductLeadDataLayer = BaseDataLayer &
    BaseProductLayer & {
        product_count?: string | null;
        country?: string;
    };

export type SocialLinkDataLayer = BaseDataLayer & {
    social_network?: string;
    social_link?: string;
    page_url?: string;
};

export type WebinarClickDataLayer = BaseDataLayer & {
    webinar_name?: string;
    webinar_author?: string;
    webinar_tag?: string;
    webinar_language?: string;
};

export type WebinarLeadDataLayer = BaseDataLayer & {
    webinar_name?: string;
    webinar_author?: string;
    webinar_tag?: string;
    webinar_language?: string;
};

export type CatalogueClickDataLayer = BaseDataLayer & {
    catalogue_name?: string;
    catalogue_link?: string;
};

export type CatalogueLeadDataLayer = BaseDataLayer & {
    country?: string;
};

export type FormLeadDataLayer = BaseDataLayer &
    BasePageLayer & {
        form_name?: string;
    };

export type MenuFormLeadDataLayer = BaseDataLayer &
    BasePageLayer & {
        form_name?: string;
    };

export type SidePanelOpenDataLayer = BaseDataLayer &
    BasePageLayer & {
        button_text: string;
        form_type?: string;
    };

export type WhitePaperDownloadDataLayer = BaseDataLayer & {
    whitepaper_name?: string;
    whitepaper_link?: string;
};

export type ImageGalleryClickDataLayer = BaseDataLayer & {
    image_url?: string;
};

export type VideoPlayDataLayer = BaseDataLayer & {
    video_link?: string;
};

export type CategoryFilterDataLayer = BaseDataLayer & {
    filter_option?: string;
};

export type InternalSearchDataLayer = BaseDataLayer & {
    query?: string;
};

export type NewsletterFormDataLayer = BaseDataLayer &
    BasePageLayer & {
        form_name?: string;
    };

export type MorePlayFormDataLayer = BaseDataLayer &
    BasePageLayer & {
        form_name?: string;
    };

export type TenderTextFormDataLayer = BaseDataLayer &
    BasePageLayer & {
        form_name?: string;
        product_name?: string;
        product_id?: string;
    };

export type FavoritesConfiguratorDataLayer = BaseDataLayer & BasePageLayer & BaseProductLayer;
export type FavoritesAddToComparisonDataLayer = BaseDataLayer & BaseProductLayer & BasePageLayer;
export type FavoritesShareLinkDataLayer = BaseDataLayer & BaseProductLayer & BasePageLayer;
export type FavoritesOpenLinkDataLayer = BaseDataLayer & BasePageLayer;
export type FavoritesAddToFavoritesDataLayer = BaseDataLayer & BaseProductLayer & BasePageLayer;
export type FavoritesRemoveFromFavoritesDataLayer = BaseDataLayer &
    BaseProductLayer &
    BasePageLayer;
export type FavoritesFormLeadDataLayer = BaseDataLayer &
    BaseProductLayer &
    BasePageLayer & {
        form_name: string;
    };
export type FavoritesFormTenderTextDataLayer = BaseDataLayer &
    BaseProductLayer &
    BasePageLayer & {
        form_name: string;
    };

export type FavoritesProductsComparedDataLayer = BaseDataLayer &
    BasePageLayer & {
        comparison_count: string;
        product1_name?: string;
        product1_id?: string;
        product1_cateogry?: string;
        product1_cateogry2?: string;
        product1_cateogry3?: string;
        product2_name?: string;
        product2_id?: string;
        product2_cateogry?: string;
        product2_cateogry2?: string;
        product2_cateogry3?: string;
        product3_name?: string;
        product3_id?: string;
        product3_cateogry?: string;
        product3_cateogry2?: string;
        product3_cateogry3?: string;
    };

export type FormBeginDataLayer = BasePageLayer & {
    form_name: string; //heading field
};

export type OpenConfiguratorDataLayer = BaseDataLayer & BasePageLayer & BaseProductLayer;

export type RecommendationsFormLeadDataLayer = BaseDataLayer &
    BasePageLayer & {
        form_name: string;
    };
export type RecommendationsOpenFormLeadDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsStartGuideDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsCloseGuideDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsCompleteGuideDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsPreviousStepDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsWatchedVideoDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsSkippedVideoDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsChoseAreaDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsSkippedAreaDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsChoseLocationDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsSkippedLocationDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsChosePlaceDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsSkippedPlaceDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsChoseCapacityDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsSkippedCapacityDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsChoseActivityDataLayer = BaseDataLayer & BasePageLayer;
export type RecommendationsSkippedActivityDataLayer = BaseDataLayer & BasePageLayer;
