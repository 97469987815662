import { Link, M70MediaItem } from '~/lib/data-contract';
import {
    CountrySelectorDataLayer,
    DownloadProductFileDataLayer,
    PageViewDataLayer,
    ProductLeadDataLayer,
    SocialLinkDataLayer,
    TrackingDataLayer,
    TrackingEvent,
    UseTrackingModel,
    ViewProduct360DataLayer,
    ViewProductDataLayer,
    WebinarClickDataLayer,
    WebinarLeadDataLayer,
    CatalogueClickDataLayer,
    CatalogueLeadDataLayer,
    FormLeadDataLayer,
    MenuFormLeadDataLayer,
    SidePanelOpenDataLayer,
    WhitePaperDownloadDataLayer,
    ImageGalleryClickDataLayer,
    VideoPlayDataLayer,
    CategoryFilterDataLayer,
    InternalSearchDataLayer,
    TrackingEnvironmentEnum,
    NewsletterFormDataLayer,
    onGtmLoaded,
    TenderTextFormDataLayer,
    FavoritesAddToComparisonDataLayer,
    FavoritesConfiguratorDataLayer,
    FavoritesProductsComparedDataLayer,
    FavoritesAddToFavoritesDataLayer,
    FavoritesFormLeadDataLayer,
    FavoritesShareLinkDataLayer,
    FavoritesOpenLinkDataLayer,
    FormBeginDataLayer,
    OpenConfiguratorDataLayer,
    FavoritesRemoveFromFavoritesDataLayer,
    RecommendationsFormLeadDataLayer,
    RecommendationsOpenFormLeadDataLayer,
    RecommendationsStartGuideDataLayer,
    RecommendationsCloseGuideDataLayer,
    RecommendationsCompleteGuideDataLayer,
    RecommendationsPreviousStepDataLayer,
    RecommendationsWatchedVideoDataLayer,
    RecommendationsSkippedVideoDataLayer,
    RecommendationsChoseAreaDataLayer,
    RecommendationsSkippedAreaDataLayer,
    RecommendationsChoseLocationDataLayer,
    RecommendationsSkippedLocationDataLayer,
    RecommendationsChosePlaceDataLayer,
    RecommendationsSkippedPlaceDataLayer,
    RecommendationsChoseCapacityDataLayer,
    RecommendationsSkippedCapacityDataLayer,
    RecommendationsChoseActivityDataLayer,
    RecommendationsSkippedActivityDataLayer,
    MorePlayFormDataLayer,
} from '$shared/components';
import { useFrame } from '$shared/utils';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { useCookieConsent } from '~/shared/hooks/useCookieConsent';

declare global {
    interface Window {
        dataLayer?: TrackingDataLayer[];
    }
}

const isServer = typeof window === 'undefined';
const { TRACKING_ENVIRONMENT } = publicRuntimeConfig();

export const useTracking = (): UseTrackingModel => {
    const { getMarketingParams } = useCookieConsent();
    const { data: frame } = useFrame();

    const getInitialUrl = () => {
        if (isServer) return;
        return getMarketingParams()?.og_location;
    };

    const getLocationUrl = () => {
        if (isServer) return;

        return window.location.href;
    };

    const getLocationPathname = () => {
        if (isServer) return;

        return window.location.pathname;
    };

    const trackLink = (link?: Link, headline?: string) => {
        if (link?.url) {
            // We agreed to do this hacky ting to determine if it's a catalogue link.
            const isCatalogueLink =
                link.url.includes('publications.kompan') && link.url.includes('catalogues');
            const isWhitePaperLink =
                link.url.includes('publications.kompan') && link.url.includes('white-papers');

            if (isCatalogueLink) {
                trackCatalogueClick({
                    catalogue_name: headline || link.title,
                    catalogue_link: link.url,
                });
            }
            if (isWhitePaperLink) {
                trackWhitePaperDownload({
                    whitepaper_name: headline || link.title,
                    whitepaper_link: link.url,
                });
            }
        }
    };

    const trackMedia = (media?: M70MediaItem) => {
        if (media?.image?.src && media?.image?.type === 'image' && !media?.externalVideoSrc) {
            trackImageGalleryClick({
                image_url: media.image.src,
            });
        }
        if ((media?.image?.src && media?.image?.type === 'file') || media?.externalVideoSrc) {
            trackVideoPlay({
                video_link: media.externalVideoSrc || media.image.src,
            });
        }
    };

    const tracking = (trackingDataLayer: TrackingDataLayer) => {
        if (isServer) return;

        onGtmLoaded()
            .then(() => {
                window.dataLayer?.push(trackingDataLayer);
            })
            .catch((error) => {
                console.error('onGtmLoadedError: ', error);
            });
    };

    const trackPageView = (pageViewDataLayer: PageViewDataLayer) => {
        tracking({
            event: TrackingEvent.PAGE_VIEW,
            environment: TRACKING_ENVIRONMENT || TrackingEnvironmentEnum.DEV,
            market: frame?.market?.market,
            og_location: getInitialUrl(),
            page_url: getLocationUrl(),
            page_path: getLocationPathname(),
            ...pageViewDataLayer,
        });
    };

    const trackViewProduct = (viewProductDataLayer: ViewProductDataLayer) => {
        tracking({
            event: TrackingEvent.VIEW_PRODUCT,
            ...viewProductDataLayer,
        });
    };

    const trackViewProduct360 = (viewProduct360DataLayer: ViewProduct360DataLayer) => {
        tracking({
            event: TrackingEvent.VIEW_PRODUCT_360,
            page_url: getLocationUrl(),
            ...viewProduct360DataLayer,
        });
    };

    const trackCountrySelector = (countrySelectorDataLayer: CountrySelectorDataLayer) => {
        tracking({
            event: TrackingEvent.COUNTRY_SELECTOR,
            ...countrySelectorDataLayer,
        });
    };

    const trackDownloadProductFile = (
        downloadProductFileDataLayer: DownloadProductFileDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.PDP_DOWNLOADS,
            page_url: getLocationUrl(),
            ...downloadProductFileDataLayer,
        });
    };

    const trackProductLead = (productLeadDataLayer: ProductLeadDataLayer) => {
        tracking({
            event: TrackingEvent.PRODUCT_LEAD,
            ...productLeadDataLayer,
        });
    };

    const trackSocialLinkClicked = (socialLinkDataLayer: SocialLinkDataLayer) => {
        tracking({
            event: TrackingEvent.SOCIAL_CLICK,
            page_url: getLocationUrl(),
            ...socialLinkDataLayer,
        });
    };

    const trackWebinarClick = (webinarClickDataLayer: WebinarClickDataLayer) => {
        tracking({
            event: TrackingEvent.WEBINAR_CLICK,
            ...webinarClickDataLayer,
        });
    };

    const trackWebinarLead = (webinarLeadDataLayer: WebinarLeadDataLayer) => {
        tracking({
            event: TrackingEvent.WEBINAR_LEAD,
            ...webinarLeadDataLayer,
        });
    };

    const trackCatalogueClick = (catalogueClickDataLayer: CatalogueClickDataLayer) => {
        tracking({
            event: TrackingEvent.CATALOGUE_CLICK,
            ...catalogueClickDataLayer,
        });
    };

    const trackCatalogueLead = (catalogueLeadDataLayer: CatalogueLeadDataLayer) => {
        tracking({
            event: TrackingEvent.CATALOGUE_LEAD,
            ...catalogueLeadDataLayer,
        });
    };

    const trackFormLead = (formLeadDataLayer: FormLeadDataLayer) => {
        tracking({
            event: TrackingEvent.FORM_LEAD,
            page_url: getLocationUrl(),
            ...formLeadDataLayer,
        });
    };

    const trackMenuFormLead = (MenuFormLeadDataLayer: MenuFormLeadDataLayer) => {
        tracking({
            event: TrackingEvent.MENU_FORM_LEAD,
            page_url: getLocationUrl(),
            ...MenuFormLeadDataLayer,
        });
    };

    const trackSidePanelOpen = (sidePanelOpenDataLayer: SidePanelOpenDataLayer) => {
        tracking({
            event: TrackingEvent.SIDE_PANEL_OPEN,
            page_url: getLocationUrl(),
            ...sidePanelOpenDataLayer,
        });
    };

    const trackWhitePaperDownload = (whitePaperDownloadDataLayer: WhitePaperDownloadDataLayer) => {
        tracking({
            event: TrackingEvent.WHITE_PAPER_DOWNLOAD,
            ...whitePaperDownloadDataLayer,
        });
    };

    const trackImageGalleryClick = (imageGalleryClickDataLayer: ImageGalleryClickDataLayer) => {
        tracking({
            event: TrackingEvent.IMAGE_GALLERY_CLICK,
            ...imageGalleryClickDataLayer,
        });
    };

    const trackVideoPlay = (videoPlayDataLayer: VideoPlayDataLayer) => {
        tracking({
            event: TrackingEvent.VIDEO_PLAY,
            ...videoPlayDataLayer,
        });
    };

    const trackCategoryFilter = (categoryFilterDataLayer: CategoryFilterDataLayer) => {
        tracking({
            event: TrackingEvent.CATEGORY_FILTER,
            ...categoryFilterDataLayer,
        });
    };

    const trackInternalSearch = (internalSearchDataLayer: InternalSearchDataLayer) => {
        tracking({
            event: TrackingEvent.INTERNAL_SEARCH,
            ...internalSearchDataLayer,
        });
    };

    const trackNewsletterForm = (newsletterFormDataLayer: NewsletterFormDataLayer) => {
        tracking({
            event: TrackingEvent.NEWSLETTER_FORM_LEAD,
            page_url: getLocationUrl(),
            ...newsletterFormDataLayer,
        });
    };

    const trackTenderTextForm = (tenderTextFormDataLayer: TenderTextFormDataLayer) => {
        tracking({
            event: TrackingEvent.TENDER_TEXT_FORM_LEAD,
            page_url: getLocationUrl(),
            ...tenderTextFormDataLayer,
        });
    };

    const trackMorePlayForm = (morePlayFormDataLayer: MorePlayFormDataLayer) => {
        tracking({
            event: TrackingEvent.MORE_PLAY_FORM,
            page_url: getLocationUrl(),
            ...morePlayFormDataLayer,
        });
    };

    const trackFavoritesConfigurator = (
        favoritesConfiguratorDataLayer: FavoritesConfiguratorDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.FAVORITES_ADD_TO_COMPARISON,
            page_url: getLocationUrl(),
            ...favoritesConfiguratorDataLayer,
        });
    };

    const trackFavoritesAddToComparison = (
        favoritesAddToComparisonDataLayer: FavoritesAddToComparisonDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.FAVORITES_ADD_TO_COMPARISON,
            page_url: getLocationUrl(),
            ...favoritesAddToComparisonDataLayer,
        });
    };

    const trackFavoritesProductsCompared = (
        favoritesProductsComparedDataLayer: FavoritesProductsComparedDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.FAVORITES_PRODUCTS_COMPARED,
            page_url: getLocationUrl(),
            ...favoritesProductsComparedDataLayer,
        });
    };

    const trackFavoritesAddToFavorites = (
        favoritesAddToFavoritesDataLayer: FavoritesAddToFavoritesDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.FAVORITES_ADD_TO_FAVORITES,
            page_url: getLocationUrl(),
            ...favoritesAddToFavoritesDataLayer,
        });
    };

    const trackFavoritesRemoveFromFavorites = (
        favoritesRemoveFromFavoritesDataLayer: FavoritesRemoveFromFavoritesDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.FAVORITES_REMOVE_FROM_FAVORITES,
            page_url: getLocationUrl(),
            ...favoritesRemoveFromFavoritesDataLayer,
        });
    };

    const trackFavoritesFormLead = (favoritesFormLeadDataLayer: FavoritesFormLeadDataLayer) => {
        tracking({
            event: TrackingEvent.FAVORITES_FORM_LEAD,
            page_url: getLocationUrl(),
            ...favoritesFormLeadDataLayer,
        });
    };

    const trackFavoritesFormTenderLead = (
        favoritesFormTenderLeadDataLayer: FavoritesFormLeadDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.FAVORITES_FORM_TENDER_LEAD,
            page_url: getLocationUrl(),
            ...favoritesFormTenderLeadDataLayer,
        });
    };

    const trackFavoritesShareLink = (favoritesShareDataLayer: FavoritesShareLinkDataLayer) => {
        tracking({
            event: TrackingEvent.FAVORITES_FAVORITES_SHARE_LINK,
            page_url: getLocationUrl(),
            ...favoritesShareDataLayer,
        });
    };

    const trackFavoritesOpenLink = (favoritesOpenDataLayer: FavoritesOpenLinkDataLayer) => {
        tracking({
            event: TrackingEvent.FAVORITES_FAVORITES_OPEN_LINK,
            page_url: getLocationUrl(),
            ...favoritesOpenDataLayer,
        });
    };

    const trackFormBegin = (formBeginDataLayer: FormBeginDataLayer) => {
        tracking({
            event: TrackingEvent.FORM_BEGIN,
            page_url: getLocationUrl(),
            ...formBeginDataLayer,
        });
    };

    const trackOpenConfigurator = (openConfigurator: OpenConfiguratorDataLayer) => {
        tracking({
            event: TrackingEvent.OPEN_CONFIGURATOR,
            page_url: getLocationUrl(),
            ...openConfigurator,
        });
    };

    const trackRecommendationsFormLead = (
        recommendationsFormLeadDataLayer: RecommendationsFormLeadDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_FORM_LEAD,
            page_url: getLocationUrl(),
            ...recommendationsFormLeadDataLayer,
        });
    };

    const trackRecommendationOpenForm = (
        recommendationsOpenFormLeadDataLayer: RecommendationsOpenFormLeadDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_OPENED_FORM_LEAD,
            page_url: getLocationUrl(),
            ...recommendationsOpenFormLeadDataLayer,
        });
    };

    const trackRecommendationStartGuide = (
        recommendationsStartGuideDataLayer: RecommendationsStartGuideDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_START_GUIDE,
            page_url: getLocationUrl(),
            ...recommendationsStartGuideDataLayer,
        });
    };

    const trackRecommendationCloseGuide = (
        recommendationsCloseGuideDataLayer: RecommendationsCloseGuideDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_CLOSE_GUIDE,
            page_url: getLocationUrl(),
            ...recommendationsCloseGuideDataLayer,
        });
    };

    const trackRecommendationCompleteGuide = (
        recommendationsCompleteGuideDataLayer: RecommendationsCompleteGuideDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_COMPLETE_GUIDE,
            page_url: getLocationUrl(),
            ...recommendationsCompleteGuideDataLayer,
        });
    };

    const trackRecommendationPreviousStep = (
        recommendationsPreviousStepDataLayer: RecommendationsPreviousStepDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_PREVIOUS_STEP,
            page_url: getLocationUrl(),
            ...recommendationsPreviousStepDataLayer,
        });
    };

    const trackRecommendationWatchedVideo = (
        recommendationsWatchedVideoDataLayer: RecommendationsWatchedVideoDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_WATCHED_VIDEO,
            page_url: getLocationUrl(),
            ...recommendationsWatchedVideoDataLayer,
        });
    };

    const trackRecommendationSkippedVideo = (
        recommendationsSkippedVideoDataLayer: RecommendationsSkippedVideoDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_SKIPPED_VIDEO,
            page_url: getLocationUrl(),
            ...recommendationsSkippedVideoDataLayer,
        });
    };

    const trackRecommendationChoseArea = (
        recommendationsChoseAreaDataLayer: RecommendationsChoseAreaDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_CHOSE_AREA,
            page_url: getLocationUrl(),
            ...recommendationsChoseAreaDataLayer,
        });
    };

    const trackRecommendationSkippedArea = (
        recommendationsSkippedAreaDataLayer: RecommendationsSkippedAreaDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_SKIPPED_AREA,
            page_url: getLocationUrl(),
            ...recommendationsSkippedAreaDataLayer,
        });
    };

    const trackRecommendationChoseLocation = (
        recommendationsChoseLocationDataLayer: RecommendationsChoseLocationDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_CHOSE_LOCATION,
            page_url: getLocationUrl(),
            ...recommendationsChoseLocationDataLayer,
        });
    };

    const trackRecommendationSkippedLocation = (
        recommendationsSkippedLocationDataLayer: RecommendationsSkippedLocationDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_SKIPPED_LOCATION,
            page_url: getLocationUrl(),
            ...recommendationsSkippedLocationDataLayer,
        });
    };

    const trackRecommendationChosePlace = (
        recommendationsChosePlaceDataLayer: RecommendationsChosePlaceDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_CHOSE_PLACE,
            page_url: getLocationUrl(),
            ...recommendationsChosePlaceDataLayer,
        });
    };

    const trackRecommendationSkippedPlace = (
        recommendationsSkippedPlaceDataLayer: RecommendationsSkippedPlaceDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_SKIPPED_PLACE,
            page_url: getLocationUrl(),
            ...recommendationsSkippedPlaceDataLayer,
        });
    };

    const trackRecommendationChoseCapacity = (
        recommendationsChoseCapacityDataLayer: RecommendationsChoseCapacityDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_CHOSE_CAPACITY,
            page_url: getLocationUrl(),
            ...recommendationsChoseCapacityDataLayer,
        });
    };

    const trackRecommendationSkippedCapacity = (
        recommendationsSkippedCapacityDataLayer: RecommendationsSkippedCapacityDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_SKIPPED_CAPACITY,
            page_url: getLocationUrl(),
            ...recommendationsSkippedCapacityDataLayer,
        });
    };

    const trackRecommendationChoseActivity = (
        recommendationsChoseActivityDataLayer: RecommendationsChoseActivityDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_CHOSE_ACTIVITY,
            page_url: getLocationUrl(),
            ...recommendationsChoseActivityDataLayer,
        });
    };

    const trackRecommendationSkippedActivity = (
        recommendationsSkippedActivityDataLayer: RecommendationsSkippedActivityDataLayer,
    ) => {
        tracking({
            event: TrackingEvent.RECOMMENDATIONS_SKIPPED_ACTIVITY,
            page_url: getLocationUrl(),
            ...recommendationsSkippedActivityDataLayer,
        });
    };

    return {
        getInitialUrl,
        getLocationUrl,
        getLocationPathname,
        trackLink,
        trackMedia,
        tracking,
        trackPageView,
        trackViewProduct,
        trackViewProduct360,
        trackCountrySelector,
        trackDownloadProductFile,
        trackProductLead,
        trackSocialLinkClicked,
        trackWebinarClick,
        trackWebinarLead,
        trackCatalogueClick,
        trackCatalogueLead,
        trackFormLead,
        trackMenuFormLead,
        trackSidePanelOpen,
        trackWhitePaperDownload,
        trackImageGalleryClick,
        trackVideoPlay,
        trackCategoryFilter,
        trackInternalSearch,
        trackNewsletterForm,
        trackTenderTextForm,
        trackMorePlayForm,
        trackFavoritesAddToComparison,
        trackFavoritesConfigurator,
        trackFavoritesProductsCompared,
        trackFavoritesAddToFavorites,
        trackFavoritesRemoveFromFavorites,
        trackFavoritesFormLead,
        trackFavoritesFormTenderLead,
        trackFavoritesShareLink,
        trackFavoritesOpenLink,
        trackFormBegin,
        trackOpenConfigurator,
        trackRecommendationsFormLead,
        trackRecommendationOpenForm,
        trackRecommendationStartGuide,
        trackRecommendationCloseGuide,
        trackRecommendationCompleteGuide,
        trackRecommendationPreviousStep,
        trackRecommendationWatchedVideo,
        trackRecommendationSkippedVideo,
        trackRecommendationChoseArea,
        trackRecommendationSkippedArea,
        trackRecommendationChoseLocation,
        trackRecommendationSkippedLocation,
        trackRecommendationChosePlace,
        trackRecommendationSkippedPlace,
        trackRecommendationChoseCapacity,
        trackRecommendationSkippedCapacity,
        trackRecommendationChoseActivity,
        trackRecommendationSkippedActivity,
    };
};
